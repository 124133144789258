window.addEventListener("load", function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#ed8b16",
                "text": "#ffffff"
            },
            "button": {
                "background": "#eeeeee",
                "text": "#333333"
            }
        },
        "theme": "classic",
        "content": {
            "message": "Utilizziamo cookie tecnici offrirti la migliore esperienza sul nostro sito: se continui a navigare accetterai l'uso di tali cookie. Per saperne di più ",
            "dismiss": "OK!",
            "link": "Cookie Policy",
            "href": "cookie-policy.html"
        }
    })
});